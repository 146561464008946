import React from 'react';
import './App.css';
import { MemoryRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './components/login/Login';
import Header from './components/header/Header';
import SidebarLeft from './components/sidebarLeft/SidebarLeft';
import DashboardSummaries from './components/dashboard/Dashboard';
import Customer from './components/customer/Customer';
import Category from './components/category/Category';
import PrivateRoute from './components/privateRoute/PrivateRoute';
import Product from './components/product/Product';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/dashboard/*" element={<PrivateRoute element={<DashboardLayout />} />} />
      </Routes>
    </Router>
  );
}

const DashboardLayout = () => (
  <section className="body">
    <Header />
    <div className="inner-wrapper">
      <SidebarLeft />
      <section role="main" className="content-body">
        <Routes>
          <Route path="summaries" element={<DashboardSummaries />} />
          <Route path="customer" element={<Customer />} />
          <Route path="category" element={<Category />} />
          <Route path="product" element={<Product />} />
          <Route path="*" element={<DashboardSummaries />} />
        </Routes>
      </section>
    </div>
  </section>
);

export default App;
