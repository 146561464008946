import React from "react";
import Draggable from "react-draggable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import "./Dashboard.css"; // Importar estilos

const Dashboard = () => {
  const comandas = [
    { id: 1, items: ["Café", "Croissant"], total: "$5.00" },
    { id: 2, items: ["Jugo de Naranja", "Tostada"], total: "$7.50" },
    { id: 3, items: ["Cerveza", "Papas Fritas"], total: "$12.00" },
    { id: 4, items: ["Café", "Croissant"], total: "$5.00" },
    { id: 5, items: ["Jugo de Naranja", "Tostada"], total: "$7.50" },
    { id: 6, items: ["Cerveza", "Papas Fritas"], total: "$12.00" },
    { id: 7, items: ["Café", "Croissant"], total: "$5.00" },
    { id: 8, items: ["Jugo de Naranja", "Tostada"], total: "$7.50" },
    { id: 9, items: ["Cerveza", "Papas Fritas"], total: "$12.00" },
    { id: 10, items: ["Café", "Croissant", "Cerveza", "Papas Fritas", "Jugo de Naranja", "Tostada"], total: "$5.00" },
    { id: 11, items: ["Jugo de Naranja", "Tostada"], total: "$7.50" },
    { id: 12, items: ["Cerveza", "Papas Fritas"], total: "$12.00" },
  ];

  return (
    <div className="dashboard">
      {comandas.map((comanda) => (
        <Draggable key={comanda.id}>
          <div className="post-it">
            {/* Chincheta */}
            <div className="post-it-pin"></div>

            <h3 className="post-it-title">Mesa {comanda.id}</h3>
            <ul className="post-it-list">
              {comanda.items.map((item, index) => (
                <li key={index} className="post-it-item">
                  <span>{item}</span>
                  {index === 0 && (
                    <FontAwesomeIcon
                      icon={faCheck}
                      style={{ marginLeft: "8px", color: "#28a745" }}
                    />
                  )}
                </li>
              ))}
            </ul>
            <div className="post-it-total">Total: {comanda.total}</div>
          </div>
        </Draggable>
      ))}
    </div>
  );
};

export default Dashboard;
