import React, { useState, useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';

const ImageUploaderWithCrop = ({ onImageChange }) => {
  const [image, setImage] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const cropperRef = useRef(null);

  // Función para manejar los archivos seleccionados
  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file) {
      setImage(URL.createObjectURL(file));
      setCroppedImage(null); // Reinicia la imagen recortada
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop,
  });

  const handleCrop = () => {
    if (cropperRef.current) {
      // Obtén el canvas con la resolución deseada
      const canvas = cropperRef.current.cropper.getCroppedCanvas({
        // Puedes ajustar maxWidth y maxHeight si necesitas una resolución específica
        maxWidth: 536,
        maxHeight: 530,
      });

      if (canvas) {
        // Usa WebP con calidad de 0.85 para obtener un buen equilibrio entre calidad y tamaño de archivo
        const croppedData = canvas.toDataURL('image/png', 1.0);
        setCroppedImage(croppedData);
        if (onImageChange) onImageChange(croppedData);
      }
    }
  };

  // Función para manejar el cambio de imagen
  const handleChangeImage = () => {
    setImage(null); // Limpia la imagen cargada
    setCroppedImage(null); // Limpia la imagen recortada
  };

  return (
    <div className="image-uploader-container">
      <div className="left-panel">
        {!image && (
          <div {...getRootProps({ className: 'dropzone' })}>
            <input {...getInputProps()} />
            <p>Drag and drop an image or click to select</p>
          </div>
        )}

        {image && (
          <div className="cropper-container">
            <Cropper
              src={image}
              className='cropper-size'
              guides={false} // Desactiva las guías de ayuda
              ref={cropperRef}
              aspectRatio={NaN} // No se establece un aspecto fijo
              toggleDragModeOnDblclick={false}
            />
            <div className="button-group">
              <button onClick={handleCrop} className="btn btn-primary button-crop-margin">
                Select image
              </button>
              <button onClick={handleChangeImage} className="btn btn-danger button-crop-margin">
                Clear
              </button>
            </div>
          </div>
        )}
      </div>

      <div className="right-panel">
        {croppedImage ? (
          <div className="cropped-preview">
            <h4>Your image</h4>
            <img src={croppedImage} alt="Cropped" />
          </div>
        ) : (
          <p></p>
        )}
      </div>
    </div>
  );
};

export default ImageUploaderWithCrop;
