import React, { useState, useEffect } from 'react';
import CategoryService from '../../services/CategoryService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';

const Category = () => {
  const [category, setCategory] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [pageSize] = useState(10);
  const [totalElements, setTotalElements] = useState(0);
  const [numberOfElements, setNumberOfElements] = useState(0);
  const [newCategory, setNewCategory] = useState({ description: '' });
  const [isEditing, setIsEditing] = useState(false);
  const [editCategoryId, setEditCategoryId] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  const [filterDescription, setFilterDescription] = useState('');
  const [filterEnabled, setFilterEnabled] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    fetchCategory(currentPage);
  }, [currentPage, filterDescription, filterEnabled]);

  const fetchCategory = async (page) => {
    const filters = {
      description: filterDescription, // Toma el valor actual del filtro
      enabled: filterEnabled,
    };

    try {
      const data = await CategoryService.getAll(page, pageSize, filters);
      setCategory(data.content);
      setTotalPages(data.totalPages);
      setTotalElements(data.totalElements);
      setNumberOfElements(data.numberOfElements);
      setIsLoading(false);
    } catch (error) {
      setError(error.message);
      setIsLoading(false);
    }
  };

  const handlePageChange = (page) => {
    if (page >= 0 && page < totalPages) {
      setCurrentPage(page);
      setIsLoading(true);
    }
  };

  const handleInputChange = (e) => {
    setNewCategory({ ...newCategory, [e.target.name]: e.target.value });
  };

  const handleCreateCategory = async () => {

    let errors = {};

    if (!newCategory.description) {
      errors.description = 'Category description is required.';
    }

    if (Object.keys(errors).length > 0) {
      setErrorMessages(errors);
      return;
    }

    try {
      await CategoryService.createCategory(newCategory);
      fetchCategory(currentPage);
      setNewCategory({ description: '' });
      setSuccessMessage('Category created successfully!');
      setTimeout(() => setSuccessMessage(''), 3000);
      closeModal();
    } catch (error) {
      setError(error.message);
    }
  };

  const handleEditCategory = (categoryId) => {
    setIsEditing(true);
    setEditCategoryId(categoryId);
    const categoryToEdit = category.find((cat) => cat.id === categoryId);
    setNewCategory({ description: categoryToEdit.description });
    openModal();
  };

  const handleUpdateCategory = async () => {
    let errors = {};

    if (!newCategory.description) {
      errors.description = 'Category description is required.';
    }

    if (Object.keys(errors).length > 0) {
      setErrorMessages(errors);
      return;
    }

    try {
      await CategoryService.updateCategory(editCategoryId, newCategory);
      fetchCategory(currentPage);
      setIsEditing(false);
      setEditCategoryId(null);
      setNewCategory({ description: '' });
      setSuccessMessage('Category updated successfully!');
      setTimeout(() => setSuccessMessage(''), 3000);
      closeModal();
    } catch (error) {
      setError(error.message);
    }
  };

  const handleDeleteCategory = async (categoryId) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this category?');
    if (confirmDelete) {
      try {
        await CategoryService.deleteCategory(categoryId);
        fetchCategory(currentPage);
      } catch (error) {
        setError(error.message);
      }
    }
  };

  const handleEnabledChange = async (categoryId, newEnabled) => {
    try {
      const updatedCategory = { enabled: newEnabled };
      await CategoryService.updateEnabled(categoryId, updatedCategory);

      fetchCategory(currentPage);
    } catch (error) {
      setError(error.message);
    }
  };

  const [errorMessages, setErrorMessages] = useState({
    description: ''
  });

  const handleFilterDescriptionChange = (e) => {
    setFilterDescription(e.target.value);
  };

  const handleFilterEnabledChange = (e) => {
    setFilterEnabled(e.target.checked);
  };

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      if (sortConfig.direction === 'ascending') {
        return <FontAwesomeIcon icon={faSortUp} />;
      } else {
        return <FontAwesomeIcon icon={faSortDown} />;
      }
    } else {
      return <FontAwesomeIcon icon={faSort} />;
    }
  };

  const openModal = () => {
    setErrorMessages({ description: '' });
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setIsEditing(false);
    setEditCategoryId(null);
    setNewCategory({ description: '' });
  };

  const sortedCategory = () => {
    let sortedData = [...category];
    if (sortConfig.key) {
      sortedData.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortedData;
  };

  if (isLoading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <section className="body">
      {successMessage && (
        <div className="success-container">
          <p className="success-message">{successMessage}</p>
        </div>
      )}
      <header className="page-header">
        <h2>Categories</h2>
      </header>

      <section className="panel">
        <header className="panel-heading">
          <h2 className="panel-title">Category List</h2>
        </header>
        <div className="panel-body">
          <div className="button-container text-right">
            <button className="btn btn-primary" onClick={openModal}>
              Add Category
            </button>
          </div>
          <div className="filter-form row">
            <div className="col-md-6">
              <input
                type="text"
                name="description"
                className="form-control"
                placeholder="Category Description"
                value={filterDescription}
                onChange={handleFilterDescriptionChange}
              />
            </div>
            <div className="col-md-6">
              <label htmlFor="filterEnabledCheckbox" className="label-check">
                Enabled
              </label>
              <input type="checkbox"
                checked={filterEnabled}
                onChange={handleFilterEnabledChange}
              />
            </div>
          </div>

          <div className="table-responsive">
            <table className="table table-bordered table-striped mb-none">
              <thead>
                <tr>
                  <th onClick={() => handleSort('description')} style={{ whiteSpace: 'nowrap' }}>
                    Description {getSortIcon('description')}
                  </th>
                  <th onClick={() => handleSort('enabled')} style={{ whiteSpace: 'nowrap' }}>
                    Enabled {getSortIcon('enabled')}
                  </th>
                  <th className="actions-column">Actions</th>
                </tr>
              </thead>
              <tbody>
                {sortedCategory().map((category) => (
                  <tr key={category.id}>
                    <td>{category.description}</td>
                    <td className='actions-column'>
                      <input type="checkbox"
                        checked={category.enabled}
                        onChange={(e) => handleEnabledChange(category.id, e.target.checked)}
                      />
                    </td>
                    <td className="actions-column">
                      <FontAwesomeIcon
                        icon={faEdit}
                        onClick={() => handleEditCategory(category.id)}
                        style={{ cursor: 'pointer', marginRight: '10px' }}
                      />
                      <FontAwesomeIcon
                        icon={faTrash}
                        onClick={() => handleDeleteCategory(category.id)}
                        style={{ cursor: 'pointer' }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <div className="pagination-container">
          <ul className="pagination">
            <li className={currentPage === 0 ? 'disabled' : ''}>
              <a onClick={() => handlePageChange(currentPage - 1)}>Previous</a>
            </li>
            {Array.from({ length: totalPages }, (_, index) => (
              <li key={index} className={index === currentPage ? 'active' : ''}>
                <a onClick={() => handlePageChange(index)}>{index + 1}</a>
              </li>
            ))}
            <li className={currentPage === totalPages - 1 ? 'disabled' : ''}>
              <a onClick={() => handlePageChange(currentPage + 1)}>Next</a>
            </li>
          </ul>

          <div className="pagination-summary">
            <p>
              Showing {numberOfElements} of {totalElements} records. Page {currentPage + 1} of {totalPages}.
            </p>
          </div>
        </div>
      </section>

      {isModalOpen && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <div className="modal-header">
              <h3>{isEditing ? 'Edit Category' : 'Add Category'}</h3>
              <button className="close-button" onClick={closeModal}>
                X
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={(e) => e.preventDefault()}>
                <div className="form-group">
                  <input
                    type="text"
                    name="description"
                    className="form-control"
                    placeholder="Category Description"
                    value={newCategory.description}
                    onChange={handleInputChange}
                    required
                  />
                  {errorMessages.description && <div className="error-message-text">{errorMessages.description}</div>}
                </div>
                <div className="form-group text-right">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={isEditing ? handleUpdateCategory : handleCreateCategory}
                  >
                    {isEditing ? 'Update' : 'Create'}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default Category;
