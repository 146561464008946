import React, { useState, useEffect } from 'react';
import ProductService from '../../services/ProductService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import CategoryService from '../../services/CategoryService';
import ImageUploaderWithCrop from '../utils/ImageUploaderWithCrop';

const Product = () => {
  const [product, setProduct] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [pageSize] = useState(10);
  const [totalElements, setTotalElements] = useState(0);
  const [numberOfElements, setNumberOfElements] = useState(0);
  const [newProduct, setNewProduct] = useState({ name: '', description: '', price: '', currency: '', category: '', productImage: null, enabled: false });
  const [isEditing, setIsEditing] = useState(false);
  const [editProductId, setEditProductId] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  const [filterName, setFilterName] = useState('');
  const [filterDescription, setFilterDescription] = useState('');
  const [filterEnabled, setFilterEnabled] = useState('');
  const [filterCategory, setFilterCategory] = useState('');
  const [categories, setCategories] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditImage, setEditImage] = useState(true);

  useEffect(() => {
    fetchCategories();
    fetchProduct(currentPage);
  }, [currentPage, filterName, filterDescription, filterEnabled, filterCategory]);

  const fetchProduct = async (page) => {
    // Toma el valor actual del filtro
    const filters = {
      name: filterName,
      description: filterDescription,
      enabled: filterEnabled,
      categoryId: filterCategory,
    };

    try {
      const data = await ProductService.getAll(page, pageSize, filters);
      setProduct(data.content);
      setTotalPages(data.totalPages);
      setTotalElements(data.totalElements);
      setNumberOfElements(data.numberOfElements);
      setIsLoading(false);
    } catch (error) {
      setError(error.message);
      setIsLoading(false);
    }
  };

  const handlePageChange = (page) => {
    if (page >= 0 && page < totalPages) {
      setCurrentPage(page);
      setIsLoading(true);
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (name === 'category') {
      setNewProduct({
        ...newProduct,
        [name]: value
      });
    } else {
      setNewProduct({
        ...newProduct,
        [name]: type === 'checkbox' ? checked : value
      });
    }
  };

  const handleCreateProduct = async () => {

    let errors = {};

    // Validar campos requeridos
    if (!newProduct.name) {
      errors.name = 'Product name is required.';
    }
    if (!newProduct.description) {
      errors.description = 'Product description is required.';
    }
    if (!newProduct.price) {
      errors.price = 'Price is required.';
    }
    if (!newProduct.currency) {
      errors.currency = 'Currency is required.';
    }
    if (!newProduct.category) {
      errors.category = 'Category is required.';
    }

    if (Object.keys(errors).length > 0) {
      setErrorMessages(errors); // Establecer los mensajes de error
      return; // Detener el proceso si hay errores
    }

    const formData = new FormData();
    formData.append('name', newProduct.name);
    formData.append('description', newProduct.description);
    formData.append('price', newProduct.price);
    formData.append('currency', newProduct.currency);
    formData.append('categoryId', newProduct.category);
    formData.append('enabled', newProduct.enabled);

    if (newProduct.productImage) {
      const imageBlob = await base64ToBlob(newProduct.productImage);
      formData.append('productImage', imageBlob, 'product-image.png');
    }

    try {
      await ProductService.createProduct(formData);
      fetchProduct(currentPage);
      setNewProduct({ name: '', description: '', price: '', currency: '', category: '', productImage: null, enabled: false });
      setSuccessMessage('Product created successfully!');
      setTimeout(() => setSuccessMessage(''), 3000);
      closeModal();
    } catch (error) {
      setError(error.message);
    }
  };

  const handleEditProduct = (ProductId) => {
    setIsEditing(true);
    setEditProductId(ProductId);
    const productToEdit = product.find((cat) => cat.id === ProductId);
    setNewProduct({
      id: productToEdit.id,
      productImage: productToEdit.productImage,
      name: productToEdit.name,
      description: productToEdit.description,
      price: productToEdit.price,
      currency: productToEdit.currency,
      category: productToEdit.category ? productToEdit.category.id : '',
      enabled: productToEdit.enabled,
    });
    openModal();
  };

  const handleUpdateProduct = async () => {
    let errors = {};

    // Validar campos requeridos
    if (!newProduct.name) {
      errors.name = 'Product name is required.';
    }
    if (!newProduct.description) {
      errors.description = 'Product description is required.';
    }
    if (!newProduct.price) {
      errors.price = 'Price is required.';
    }
    if (!newProduct.currency) {
      errors.currency = 'Currency is required.';
    }
    if (!newProduct.category) {
      errors.category = 'Category is required.';
    }

    if (Object.keys(errors).length > 0) {
      setErrorMessages(errors);
      return;
    }

    const formData = new FormData();
    formData.append('name', newProduct.name);
    formData.append('description', newProduct.description);
    formData.append('price', newProduct.price);
    formData.append('currency', newProduct.currency);
    formData.append('categoryId', newProduct.category);
    formData.append('enabled', newProduct.enabled);

    if (newProduct.productImage && newProduct.productImage.startsWith('data:image/')) {
      const imageBlob = await base64ToBlob(newProduct.productImage);
      formData.append('productImage', imageBlob, 'product-image.png');
    }

    try {
      await ProductService.updateProduct(editProductId, formData);
      fetchProduct(currentPage);
      setIsEditing(false);
      setEditProductId(null);
      setNewProduct({ name: '', description: '', price: '', currency: '', category: '', productImage: null, enabled: false });
      setSuccessMessage('Product updated successfully!');
      setTimeout(() => setSuccessMessage(''), 3000);
      closeModal();
    } catch (error) {
      setError(error.message);
    }
  };

  const base64ToBlob = (base64) => {
    return fetch(base64)
      .then((res) => res.blob())
      .catch((err) => setError('Error converting base64 to blob:', err));
  };

  const handleDeleteProduct = async (productId) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this Product?');
    if (confirmDelete) {
      try {
        await ProductService.deleteProduct(productId);
        fetchProduct(currentPage);
      } catch (error) {
        setError(error.message);
      }
    }
  };

  const handleEnabledChange = async (productId, newEnabled) => {
    try {
      const updatedProduct = { enabled: newEnabled };
      await ProductService.updateEnabled(productId, updatedProduct);

      fetchProduct(currentPage);
    } catch (error) {
      setError(error.message);
    }
  };

  const [errorMessages, setErrorMessages] = useState({
    name: '',
    description: '',
    price: '',
    currency: '',
    category: ''
  });

  const handleFilterNameChange = (e) => {
    setFilterName(e.target.value);
  };

  const handleFilterDescriptionChange = (e) => {
    setFilterDescription(e.target.value);
  };

  const handleFilterEnabledChange = (e) => {
    setFilterEnabled(e.target.checked);
  };

  const handleFilterCategoryChange = (e) => {
    setFilterCategory(e.target.value);
  };

  const handleImageChange = (croppedImage) => {
    setNewProduct({ ...newProduct, productImage: croppedImage });
  };

  const handleEditImage = () => {
    setEditImage(true);
  };

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      if (sortConfig.direction === 'ascending') {
        return <FontAwesomeIcon icon={faSortUp} />;
      } else {
        return <FontAwesomeIcon icon={faSortDown} />;
      }
    } else {
      return <FontAwesomeIcon icon={faSort} />;
    }
  };

  const fetchCategories = async () => {
    try {
      const filters = { description: '', enabled: true };

      const data = await CategoryService.getAll(0, 100, filters);
      setCategories(data.content || []);
    } catch (error) {
      console.error("Error fetching categories:", error.message);
    }
  };

  const openModal = () => {
    setErrorMessages({ name: '', description: '', price: '', currency: '', category: '', productImage: null, enabled: false });
    fetchCategories();
    setIsModalOpen(true);
    setEditImage(false);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setIsEditing(false);
    setEditProductId(null);
    setNewProduct({ name: '', description: '', price: '', currency: '', category: '', productImage: null, enabled: false });
  };

  const sortedProduct = () => {
    let sortedData = [...product];
    if (sortConfig.key) {
      sortedData.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortedData;
  };

  if (isLoading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <section className="body">
      {successMessage && (
        <div className="success-container">
          <p className="success-message">{successMessage}</p>
        </div>
      )}
      <header className="page-header">
        <h2>Products</h2>
      </header>

      <section className="panel">
        <header className="panel-heading">
          <h2 className="panel-title">Product List</h2>
        </header>
        <div className="panel-body">
          <div className="button-container text-right">
            <button className="btn btn-primary" onClick={openModal}>
              Add Product
            </button>
          </div>
          <div className="filter-form row">
            <div className="col-md-2">
              <input
                type="text"
                name="name"
                className="form-control"
                placeholder="Product Name"
                value={filterName}
                onChange={handleFilterNameChange}
              />
            </div>
            <div className="col-md-4">
              <input
                type="text"
                name="description"
                className="form-control"
                placeholder="Product Description"
                value={filterDescription}
                onChange={handleFilterDescriptionChange}
              />
            </div>
            <div className="col-md-2">
              <select
                name="category"
                className="form-control"
                value={newProduct.category.id}
                onChange={handleFilterCategoryChange}
              >
                <option value="">All categories</option>
                {categories.map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.description}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-md-2">
              <label htmlFor="filterEnabledCheckbox" className="label-check">
                Enabled
              </label>
              <input type="checkbox"
                checked={filterEnabled}
                onChange={handleFilterEnabledChange}
              />
            </div>
          </div>

          <div className="table-responsive">
            <table className="table table-bordered table-striped mb-none">
              <thead>
                <tr>
                  <th>Image</th>
                  <th onClick={() => handleSort('name')} style={{ whiteSpace: 'nowrap' }}>
                    Name {getSortIcon('name')}
                  </th>
                  <th onClick={() => handleSort('description')} style={{ whiteSpace: 'nowrap' }}>
                    Description {getSortIcon('description')}
                  </th>
                  <th onClick={() => handleSort('price')} style={{ whiteSpace: 'nowrap' }}>
                    Price {getSortIcon('price')}
                  </th>
                  <th onClick={() => handleSort('currency')} style={{ whiteSpace: 'nowrap' }}>
                    Currency {getSortIcon('currency')}
                  </th>
                  <th onClick={() => handleSort('category')} style={{ whiteSpace: 'nowrap' }}>
                    Category {getSortIcon('category')}
                  </th>
                  <th onClick={() => handleSort('enabled')} style={{ whiteSpace: 'nowrap' }}>
                    Enabled {getSortIcon('enabled')}
                  </th>
                  <th className="actions-column">Actions</th>
                </tr>
              </thead>
              <tbody>
                {sortedProduct().map((product) => (
                  <tr key={product.id}>
                    <td><img src={`${product.productImage}?v=${new Date().getTime()}`} alt="" className="preview-image-grid" /></td>
                    <td>{product.name}</td>
                    <td>{product.description.length > 50 ? `${product.description.substring(0, 50)}...` : product.description}</td>
                    <td>{(product.price).toFixed(2)}</td>
                    <td>{product.currency}</td>
                    <td>{product.category ? product.category.description : ''}</td>
                    <td className='actions-column'>
                      <input type="checkbox"
                        checked={product.enabled}
                        onChange={(e) => handleEnabledChange(product.id, e.target.checked)}
                      />
                    </td>
                    <td className='actions-column'>
                      <FontAwesomeIcon icon={faEdit} onClick={() => handleEditProduct(product.id)} />
                      <FontAwesomeIcon
                        icon={faTrash}
                        onClick={() => handleDeleteProduct(product.id)}
                        style={{ marginLeft: '10px', cursor: 'pointer' }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <div className="pagination-container">
          <ul className="pagination">
            <li className={currentPage === 0 ? 'disabled' : ''}>
              <a onClick={() => handlePageChange(currentPage - 1)}>Previous</a>
            </li>
            {Array.from({ length: totalPages }, (_, index) => (
              <li key={index} className={index === currentPage ? 'active' : ''}>
                <a onClick={() => handlePageChange(index)}>{index + 1}</a>
              </li>
            ))}
            <li className={currentPage === totalPages - 1 ? 'disabled' : ''}>
              <a onClick={() => handlePageChange(currentPage + 1)}>Next</a>
            </li>
          </ul>

          <div className="pagination-summary">
            <p>
              Showing {numberOfElements} of {totalElements} records. Page {currentPage + 1} of {totalPages}.
            </p>
          </div>
        </div>
      </section>

      {isModalOpen && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <div className="modal-header">
              <h3>{isEditing ? 'Edit Product' : 'Add Product'}</h3>
              <button className="close-button" onClick={closeModal}>
                X
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={(e) => e.preventDefault()}>
                <div className="form-group">
                  {isEditing && !isEditImage ? (
                    <div>
                      <img
                        src={`${newProduct.productImage}?v=${new Date().getTime()}`}
                        alt=""
                        className="preview-image-modal"
                      />
                      <button type="button" onClick={handleEditImage} className="btn btn-primary">Change Image</button>
                    </div>
                  ) : null}

                  {(!isEditing || isEditImage) && (
                    <ImageUploaderWithCrop onImageChange={handleImageChange} />
                  )}
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    placeholder="Product Name"
                    value={newProduct.name}
                    onChange={handleInputChange}
                    required
                  />
                  {errorMessages.name && <div className="error-message-text">{errorMessages.name}</div>}
                </div>
                <div className="form-group">
                  <textarea
                    name="description"
                    className="form-control"
                    placeholder="Product Description"
                    value={newProduct.description}
                    onChange={handleInputChange}
                    required
                  />
                  {errorMessages.description && <div className="error-message-text">{errorMessages.description}</div>}
                </div>
                <div className="form-group">
                  <input
                    type="number"
                    name="price"
                    className="form-control"
                    placeholder="Price"
                    value={newProduct.price}
                    onChange={handleInputChange}
                    required
                  />
                  {errorMessages.price && <div className="error-message-text">{errorMessages.price}</div>}
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    name="currency"
                    className="form-control"
                    placeholder="Currency"
                    value={newProduct.currency}
                    onChange={handleInputChange}
                    required
                  />
                  {errorMessages.currency && <div className="error-message-text">{errorMessages.currency}</div>}
                </div>

                <div className="form-group">
                  <select
                    name="category"
                    className="form-control"
                    value={newProduct.category}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="">Select a category</option>
                    {categories.map((category) => (
                      <option key={category.id} value={category.id}>
                        {category.description}
                      </option>
                    ))}
                  </select>
                  {errorMessages.category && <div className="error-message-text">{errorMessages.category}</div>}
                </div>
                <div className="form-group text-right">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={isEditing ? handleUpdateProduct : handleCreateProduct}
                  >
                    {isEditing ? 'Update' : 'Create'}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default Product;
