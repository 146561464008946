import React from 'react';
import Modal from 'react-modal';

const ViewWebPageModal = ({ isOpen, onClose, url }) => {
    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            contentLabel="Smartmenu Viewer"
            className="full-screen-modal"
            overlayClassName="full-screen-overlay"
        >
            <div className="button-container button-crop-margin">
                <button onClick={onClose} className='bt btn-danger'>
                    Close
                </button>
            </div>

            <iframe
                src={url}
                title="Web Page"
                className='iframe-modal'
            />
        </Modal>
    );
};

export default ViewWebPageModal;
