import React from 'react';

const ColorPicker = ({ value, onColorChange, disabled }) => {
  const handleColorChange = (event) => {
    const newColor = event.target.value;
    onColorChange(newColor);
  };

  const handleTextChange = (event) => {
    const newColor = event.target.value;
    onColorChange(newColor);
  };

  return (
    <div className="color-picker-container">

      {/* Text input for manual color entry */}
      <input
        type="text"
        className="form-control color-text-input color-picker-button-size"
        value={value}
        onChange={handleTextChange}
        placeholder="#000000"
        disabled={disabled}
      />

      {/* Color picker input */}
      <input
        type="color"
        className="color-display-btn color-picker-button-size"
        style={{ backgroundColor: value }}
        value={value}
        onChange={handleColorChange}
        disabled={disabled}
      />

    </div>
  );
};

export default ColorPicker;
