import React, { useState, useEffect } from "react";
import ImageUploaderWithCrop from '../utils/ImageUploaderWithCrop';
import ColorPicker from "../utils/ColorPicker";
import ViewWebPageModal from "../utils/ViewWebPageModal";
import CustomerService from "../../services/CustomerService";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';

const Customer = () => {
    const [customer, setCustomer] = useState([]);
    const [newCustomer, setNewCustomer] = useState({ logoImage: null, primaryColor: '', fontPrimaryColor: '', headerFooterColor: '', fontHeaderFooterColor: '', code: '', name: '', displayName: '', address: '', phone: '', email: '', mondayIsOpen: false, mondayDescription: '', tuesdayIsOpen: false, tuesdayDescription: '', wednesdayIsOpen: false, wednesdayDescription: '', thursdayIsOpen: false, thursdayDescription: '', fridayIsOpen: false, fridayDescription: '', saturdayIsOpen: false, saturdayDescription: '', sundayIsOpen: false, sundayDescription: '', holidayIsOpen: false, holidayDescription: '', facebook: false, facebookLink: '', twitter: false, twitterLink: '', linkedin: false, linkedinLink: '', instagram: false, instagramLink: '', whatsapp: false, whatsappLink: '', enabled: true });
    const [isLoading, setIsLoading] = useState(true);
    const [successMessage, setSuccessMessage] = useState('');
    const [generalErrorMessage, setGeneralErrorMessage] = useState('');
    const [error, setError] = useState(null);
    const [editMode, setEditMode] = useState(false);
    const [isEditImage, setEditImage] = useState(false);
    const [isModalOpen, setModalOpen] = useState(false);
    const [currentUrl, setCurrentUrl] = useState('');

    const [activeTab, setActiveTab] = useState('generalInfo');

    useEffect(() => {

        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response = await CustomerService.getClientFromAuthenticatedUser();
            setCustomer(response);
            setNewCustomer({
                ...newCustomer,
                ...response
            });
            setIsLoading(false);
        } catch (error) {
            setError(error.message);
        }
    };

    const cacheBustedUrl = `${newCustomer?.logoImage}?v=${new Date().getTime()}`;

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setNewCustomer((prevData) => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    const [errorMessages, setErrorMessages] = useState({
        name: '',
        displayName: '',
        address: '',
        phone: '',
        email: '',
        mondayDescription: '',
        tuesdayDescription: '',
        wednesdayDescription: '',
        thursdayDescription: '',
        fridayDescription: '',
        saturdayDescription: '',
        sundayDescription: '',
        holidayDescription: '',
        facebookLink: '',
        twitterLink: '',
        linkedinLink: '',
        instagramLink: '',
        whatsappLink: ''
    });

    const handleEditCustomer = async (customerId) => {

        let errors = {};

        if (!newCustomer.name) {
            errors.name = 'Name is required.';
        }

        if (!newCustomer.displayName) {
            errors.displayName = 'Display name is required.';
        }

        if (!newCustomer.address) {
            errors.address = 'Address is required.';
        }

        if (!newCustomer.phone) {
            errors.phone = 'Phone is required.';
        }

        if (!newCustomer.email) {
            errors.email = 'Email is required.';
        }

        if (newCustomer.mondayIsOpen && newCustomer.mondayDescription === '') {
            errors.mondayDescription = 'Monday description is required when open.';
        }

        if (newCustomer.tuesdayIsOpen && newCustomer.tuesdayDescription === '') {
            errors.tuesdayDescription = 'Tuesday description is required when open.';
        }

        if (newCustomer.wednesdayIsOpen && newCustomer.wednesdayDescription === '') {
            errors.wednesdayDescription = 'Wednesday description is required when open.';
        }

        if (newCustomer.thursdayIsOpen && newCustomer.thursdayDescription === '') {
            errors.thursdayDescription = 'Thursday description is required when open.';
        }

        if (newCustomer.fridayIsOpen && newCustomer.fridayDescription === '') {
            errors.fridayDescription = 'Friday description is required when open.';
        }

        if (newCustomer.saturdayIsOpen && newCustomer.saturdayDescription === '') {
            errors.saturdayDescription = 'Saturday description is required when open.';
        }

        if (newCustomer.sundayIsOpen && newCustomer.sundayDescription === '') {
            errors.sundayDescription = 'Sunday description is required when open.';
        }

        if (newCustomer.holidayIsOpen && newCustomer.holidayDescription === '') {
            errors.holidayDescription = 'Holiday description is required when open.';
        }

        if (newCustomer.facebook && newCustomer.facebookLink === '') {
            errors.facebookLink = 'Facebook link is required when enabled.';
        }

        if (newCustomer.twitter && newCustomer.twitterLink === '') {
            errors.twitterLink = 'Twitter link is required when enabled.';
        }

        if (newCustomer.linkedin && newCustomer.linkedinLink === '') {
            errors.linkedinLink = 'LinkedIn link is required when enabled.';
        }

        if (newCustomer.instagram && newCustomer.instagramLink === '') {
            errors.instagramLink = 'Instagram link is required when enabled.';
        }

        if (newCustomer.whatsapp && newCustomer.whatsappLink === '') {
            errors.whatsappLink = 'Whatsapp phone is required when enabled.';
        }

        if (Object.keys(errors).length > 0) {
            setErrorMessages(errors);

            const errorList = Object.values(errors).join(', ');
            setGeneralErrorMessage(`Please fix the following issues: ${errorList}`);

            return;
        }

        const formData = new FormData();
        formData.append('code', customer.code);
        formData.append('primaryColor', newCustomer.primaryColor);
        formData.append('fontPrimaryColor', newCustomer.fontPrimaryColor);
        formData.append('headerFooterColor', newCustomer.headerFooterColor);
        formData.append('fontHeaderFooterColor', newCustomer.fontHeaderFooterColor);
        formData.append('name', newCustomer.name);
        formData.append('displayName', newCustomer.displayName);
        formData.append('address', newCustomer.address);
        formData.append('phone', newCustomer.phone);
        formData.append('email', newCustomer.email);
        formData.append('mondayIsOpen', newCustomer.mondayIsOpen);
        formData.append('mondayDescription', newCustomer.mondayDescription);
        formData.append('tuesdayIsOpen', newCustomer.tuesdayIsOpen);
        formData.append('tuesdayDescription', newCustomer.tuesdayDescription);
        formData.append('wednesdayIsOpen', newCustomer.wednesdayIsOpen);
        formData.append('wednesdayDescription', newCustomer.wednesdayDescription);
        formData.append('thursdayIsOpen', newCustomer.thursdayIsOpen);
        formData.append('thursdayDescription', newCustomer.thursdayDescription);
        formData.append('fridayIsOpen', newCustomer.fridayIsOpen);
        formData.append('fridayDescription', newCustomer.fridayDescription);
        formData.append('saturdayIsOpen', newCustomer.saturdayIsOpen);
        formData.append('saturdayDescription', newCustomer.saturdayDescription);
        formData.append('sundayIsOpen', newCustomer.sundayIsOpen);
        formData.append('sundayDescription', newCustomer.sundayDescription);
        formData.append('holidayIsOpen', newCustomer.holidayIsOpen);
        formData.append('holidayDescription', newCustomer.holidayDescription);
        formData.append('facebook', newCustomer.facebook);
        formData.append('facebookLink', newCustomer.facebookLink);
        formData.append('twitter', newCustomer.twitter);
        formData.append('twitterLink', newCustomer.twitterLink);
        formData.append('linkedin', newCustomer.linkedin);
        formData.append('linkedinLink', newCustomer.linkedinLink);
        formData.append('instagram', newCustomer.instagram);
        formData.append('instagramLink', newCustomer.instagramLink);
        formData.append('whatsapp', newCustomer.whatsapp);
        formData.append('whatsappLink', newCustomer.whatsappLink.replace(/\s|\+/g, '').trim());
        formData.append('enabled', newCustomer.enabled);

        if (newCustomer.logoImage && newCustomer.logoImage.startsWith('data:image/')) {
            const imageBlob = await base64ToBlob(newCustomer.logoImage);
            formData.append('logoImage', imageBlob, 'logo-image.png');
        }

        try {
            await CustomerService.updateCustomer(customer.id, formData);

            setSuccessMessage('Your information updated successfully!');
            setTimeout(() => setSuccessMessage(''), 3000);

            fetchData();

            setEditMode(false);
            setEditImage(false);
            setErrorMessages({});
            setGeneralErrorMessage('');
        } catch (error) {
            setError(error.message);
        }
    };

    const base64ToBlob = (base64) => {
        return fetch(base64)
            .then((res) => res.blob())
            .catch((err) => setError('Error converting base64 to blob:', err));
    };

    const handleCancel = () => {
        setEditMode(false);
        setEditImage(false);
    };

    const handleImageChange = (croppedImage) => {
        setNewCustomer({ ...customer, logoImage: croppedImage });
    };

    const handlePrimaryColorChange = (color) => {
        setNewCustomer({ ...newCustomer, primaryColor: color });
    };

    const handleFontPrimaryColorChange = (color) => {
        setNewCustomer({ ...newCustomer, fontPrimaryColor: color });
    };

    const handleHeaderFooterColorChange = (color) => {
        setNewCustomer({ ...newCustomer, headerFooterColor: color });
    };

    const handleFontHeaderFooterColorChange = (color) => {
        setNewCustomer({ ...newCustomer, fontHeaderFooterColor: color });
    };

    const handleEditImage = () => {
        setEditImage(true);
    };

    const viewWebPage = (code) => {
        const url = `https://tothomtec.com?code=${code}&table=1`;
        setCurrentUrl(url);
        setModalOpen(true);
    };

    if (isLoading) return <p>Loading...</p>;
    if (error) return <p>Error: {error}</p>;

    return (
        <>
            <div className="tabs">
                <button onClick={() => setActiveTab('generalInfo')} className={activeTab === 'generalInfo' ? 'active' : ''}>
                    General info
                </button>
                <button onClick={() => setActiveTab('workingHours')} className={activeTab === 'workingHours' ? 'active' : ''}>
                    Working hours
                </button>
                <button onClick={() => setActiveTab('socialMedia')} className={activeTab === 'socialMedia' ? 'active' : ''}>
                    Social media
                </button>
                <button onClick={() => setActiveTab('themes')} className={activeTab === 'themes' ? 'active' : ''}>
                    Themes
                </button>
            </div>

            <section className="panel">
                {successMessage && (
                    <div className="success-container">
                        <p className="success-message">{successMessage}</p>
                    </div>
                )}

                {generalErrorMessage && (
                    <div className="error-container">
                        <p className="error-message">{generalErrorMessage}</p>
                    </div>
                )}

                <header className="panel-heading">
                    <h2 className="panel-title">Your Information</h2>
                </header>
                <div className="panel-body">
                    <form onSubmit={(e) => e.preventDefault()}>
                        <div className="button-container">
                            <button
                                className="btn btn-primary align-left"
                                onClick={() => viewWebPage(customer.code)}
                            >
                                <FontAwesomeIcon icon={faEye} />
                            </button>

                            <button
                                className={editMode ? 'btn btn-warning space-right' : 'btn btn-primary space-right'}
                                onClick={() => {
                                    if (editMode) {
                                        handleCancel();
                                    } else {
                                        setEditMode(true);
                                    }
                                }}
                                disabled={editMode && !customer}
                            >
                                {editMode ? 'Cancel Edit' : 'Edit information'}
                            </button>

                            <button
                                className="btn btn-primary"
                                onClick={() => handleEditCustomer(customer.id)}
                                disabled={!editMode}
                            >
                                Save information
                            </button>
                        </div>

                        <div className="tabs">

                            <div className="tab">
                                {activeTab === 'generalInfo' && (
                                    <div className="card mb-4">
                                        <div className="customer-subtitle">General info</div>
                                        <div className="card-body">
                                            <div className="row mb-3">
                                                <div className="col-md-3">
                                                    <label>Name:</label>
                                                </div>
                                                <div className="col-md-2">
                                                    <input
                                                        type="text"
                                                        name="name"
                                                        className="form-control"
                                                        value={newCustomer.name}
                                                        onChange={handleInputChange}
                                                        disabled={!editMode}
                                                        required
                                                    />
                                                    {errorMessages.name && <div className="error-message-text">{errorMessages.name}</div>}
                                                </div>
                                            </div>

                                            <div className="row mb-3">
                                                <div className="col-md-3">
                                                    <label>Display name:</label>
                                                </div>
                                                <div className="col-md-2">
                                                    <input
                                                        type="text"
                                                        name="displayName"
                                                        className="form-control"
                                                        value={newCustomer.displayName}
                                                        onChange={handleInputChange}
                                                        disabled={!editMode}
                                                    />
                                                    {errorMessages.displayName && <div className="error-message-text">{errorMessages.displayName}</div>}
                                                </div>
                                            </div>

                                            <div className="row mb-3">
                                                <div className="col-md-3">
                                                    <label>Address:</label>
                                                </div>
                                                <div className="col-md-6">
                                                    <input
                                                        type="text"
                                                        name="address"
                                                        className="form-control"
                                                        value={newCustomer.address}
                                                        onChange={handleInputChange}
                                                        disabled={!editMode}
                                                    />
                                                    {errorMessages.address && <div className="error-message-text">{errorMessages.address}</div>}
                                                </div>
                                            </div>

                                            <div className="row mb-3">
                                                <div className="col-md-3">
                                                    <label>Phone:</label>
                                                </div>
                                                <div className="col-md-2">
                                                    <input
                                                        type="text"
                                                        name="phone"
                                                        className="form-control"
                                                        value={newCustomer.phone}
                                                        onChange={handleInputChange}
                                                        disabled={!editMode}
                                                    />
                                                    {errorMessages.phone && <div className="error-message-text">{errorMessages.phone}</div>}
                                                </div>
                                            </div>

                                            <div className="row mb-3">
                                                <div className="col-md-3">
                                                    <label>Email:</label>
                                                </div>
                                                <div className="col-md-6">
                                                    <input
                                                        type="text"
                                                        name="email"
                                                        className="form-control"
                                                        value={newCustomer.email}
                                                        onChange={handleInputChange}
                                                        placeholder="Email"
                                                        disabled={!editMode}
                                                    />
                                                    {errorMessages.email && <div className="error-message-text">{errorMessages.email}</div>}
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                )}
                            </div>
                            <div className="tab">
                                {activeTab === 'workingHours' && (
                                    <div className="card mb-4">
                                        <h3 className="customer-subtitle">Working hours</h3>

                                        <div className="card-body">
                                            <div className="row mb-3">
                                                <div className="col-md-3">
                                                    <input
                                                        type="checkbox"
                                                        name="mondayIsOpen"
                                                        className="form-check-input space-right"
                                                        checked={newCustomer.mondayIsOpen}
                                                        onChange={handleInputChange}
                                                        disabled={!editMode}
                                                    />
                                                    <label className="label-middle">Open on Mondays:</label>
                                                </div>
                                                <div className="col-md-4">
                                                    <input
                                                        type="text"
                                                        name="mondayDescription"
                                                        className="form-control"
                                                        value={newCustomer.mondayDescription}
                                                        onChange={handleInputChange}
                                                        placeholder="Monday timetable description"
                                                        disabled={!editMode || !customer.mondayIsOpen}
                                                    />
                                                    {errorMessages.mondayDescription && <div className="error-message-text">{errorMessages.mondayDescription}</div>}
                                                </div>
                                            </div>

                                            <div className="row mb-3">
                                                <div className="col-md-3">
                                                    <input
                                                        type="checkbox"
                                                        name="tuesdayIsOpen"
                                                        className="form-check-input space-right"
                                                        checked={newCustomer.tuesdayIsOpen}
                                                        onChange={handleInputChange}
                                                        disabled={!editMode}
                                                    />
                                                    <label className="label-middle">Open on Tuesdays:</label>
                                                </div>
                                                <div className="col-md-4">
                                                    <input
                                                        type="text"
                                                        name="tuesdayDescription"
                                                        className="form-control"
                                                        value={newCustomer.tuesdayDescription}
                                                        onChange={handleInputChange}
                                                        placeholder="Tuesday timetable description"
                                                        disabled={!editMode || !customer.tuesdayIsOpen}
                                                    />
                                                    {errorMessages.tuesdayDescription && <div className="error-message-text">{errorMessages.tuesdayDescription}</div>}
                                                </div>
                                            </div>

                                            <div className="row mb-3">
                                                <div className="col-md-3">
                                                    <input
                                                        type="checkbox"
                                                        name="wednesdayIsOpen"
                                                        className="form-check-input space-right"
                                                        checked={newCustomer.wednesdayIsOpen}
                                                        onChange={handleInputChange}
                                                        disabled={!editMode}
                                                    />
                                                    <label className="label-middle">Open on Wednesdays:</label>
                                                </div>
                                                <div className="col-md-4">
                                                    <input
                                                        type="text"
                                                        name="wednesdayDescription"
                                                        className="form-control"
                                                        value={newCustomer.wednesdayDescription}
                                                        onChange={handleInputChange}
                                                        placeholder="Wednesday timetable description"
                                                        disabled={!editMode || !newCustomer.wednesdayIsOpen}
                                                    />
                                                    {errorMessages.wednesdayDescription && <div className="error-message-text">{errorMessages.wednesdayDescription}</div>}
                                                </div>
                                            </div>

                                            <div className="row mb-3">
                                                <div className="col-md-3">
                                                    <input
                                                        type="checkbox"
                                                        name="thursdayIsOpen"
                                                        className="form-check-input space-right"
                                                        checked={newCustomer.thursdayIsOpen}
                                                        onChange={handleInputChange}
                                                        disabled={!editMode}
                                                    />
                                                    <label className="label-middle">Open on Thursdays:</label>
                                                </div>
                                                <div className="col-md-4">
                                                    <input
                                                        type="text"
                                                        name="thursdayDescription"
                                                        className="form-control"
                                                        value={newCustomer.thursdayDescription}
                                                        onChange={handleInputChange}
                                                        placeholder="Thursday timetable description"
                                                        disabled={!editMode || !newCustomer.thursdayIsOpen}
                                                    />
                                                    {errorMessages.thursdayDescription && <div className="error-message-text">{errorMessages.thursdayDescription}</div>}
                                                </div>
                                            </div>

                                            <div className="row mb-3">
                                                <div className="col-md-3">
                                                    <input
                                                        type="checkbox"
                                                        name="fridayIsOpen"
                                                        className="form-check-input space-right"
                                                        checked={newCustomer.fridayIsOpen}
                                                        onChange={handleInputChange}
                                                        disabled={!editMode}
                                                    />
                                                    <label className="label-middle">Open on Fridays:</label>
                                                </div>
                                                <div className="col-md-4">
                                                    <input
                                                        type="text"
                                                        name="fridayDescription"
                                                        className="form-control"
                                                        value={newCustomer.fridayDescription}
                                                        onChange={handleInputChange}
                                                        placeholder="Friday timetable description"
                                                        disabled={!editMode || !newCustomer.fridayIsOpen}
                                                    />
                                                    {errorMessages.fridayDescription && <div className="error-message-text">{errorMessages.fridayDescription}</div>}
                                                </div>
                                            </div>

                                            <div className="row mb-3">
                                                <div className="col-md-3">
                                                    <input
                                                        type="checkbox"
                                                        name="saturdayIsOpen"
                                                        className="form-check-input space-right"
                                                        checked={newCustomer.saturdayIsOpen}
                                                        onChange={handleInputChange}
                                                        disabled={!editMode}
                                                    />
                                                    <label className="label-middle">Open on Saturdays:</label>
                                                </div>
                                                <div className="col-md-4">
                                                    <input
                                                        type="text"
                                                        name="saturdayDescription"
                                                        className="form-control"
                                                        value={newCustomer.saturdayDescription}
                                                        onChange={handleInputChange}
                                                        placeholder="Saturday timetable description"
                                                        disabled={!editMode || !newCustomer.saturdayIsOpen}
                                                    />
                                                    {errorMessages.saturdayDescription && <div className="error-message-text">{errorMessages.saturdayDescription}</div>}
                                                </div>
                                            </div>

                                            <div className="row mb-3">
                                                <div className="col-md-3">
                                                    <input
                                                        type="checkbox"
                                                        name="sundayIsOpen"
                                                        className="form-check-input space-right"
                                                        checked={newCustomer.sundayIsOpen}
                                                        onChange={handleInputChange}
                                                        disabled={!editMode}
                                                    />
                                                    <label className="label-middle">Open on Sundays:</label>
                                                </div>
                                                <div className="col-md-4">
                                                    <input
                                                        type="text"
                                                        name="sundayDescription"
                                                        className="form-control"
                                                        value={newCustomer.sundayDescription}
                                                        onChange={handleInputChange}
                                                        placeholder="Sunday timetable description"
                                                        disabled={!editMode || !newCustomer.sundayIsOpen}
                                                    />
                                                    {errorMessages.sundayDescription && <div className="error-message-text">{errorMessages.sundayDescription}</div>}
                                                </div>
                                            </div>

                                            <div className="row mb-3">
                                                <div className="col-md-3">
                                                    <input
                                                        type="checkbox"
                                                        name="holidayIsOpen"
                                                        className="form-check-input space-right"
                                                        checked={newCustomer.holidayIsOpen}
                                                        onChange={handleInputChange}
                                                        disabled={!editMode}
                                                    />
                                                    <label className="label-middle">Open on Holidays:</label>
                                                </div>
                                                <div className="col-md-4">
                                                    <input
                                                        type="text"
                                                        name="holidayDescription"
                                                        className="form-control"
                                                        value={newCustomer.holidayDescription}
                                                        onChange={handleInputChange}
                                                        placeholder="Holiday timetable description"
                                                        disabled={!editMode || !newCustomer.holidayIsOpen}
                                                    />
                                                    {errorMessages.holidayDescription && <div className="error-message-text">{errorMessages.holidayDescription}</div>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="tabs">
                                {activeTab === 'socialMedia' && (
                                    <div className="card mb-4">
                                        <h3 className="customer-subtitle">Social Media</h3>
                                        <div className="card-body">
                                            <div className="row mb-3">
                                                <div className="col-md-3">
                                                    <input
                                                        type="checkbox"
                                                        name="facebook"
                                                        className="form-check-input space-right"
                                                        checked={newCustomer.facebook}
                                                        onChange={handleInputChange}
                                                        disabled={!editMode}
                                                    />
                                                    <label className="label-middle">Facebook:</label>
                                                </div>
                                                <div className="col-md-6">
                                                    <input
                                                        type="text"
                                                        name="facebookLink"
                                                        className="form-control"
                                                        value={newCustomer.facebookLink}
                                                        onChange={handleInputChange}
                                                        placeholder="facebook Link"
                                                        disabled={!editMode || !newCustomer.facebook}
                                                    />
                                                    {errorMessages.facebookLink && <div className="error-message-text">{errorMessages.facebookLink}</div>}
                                                </div>
                                            </div>

                                            <div className="row mb-3">
                                                <div className="col-md-3">
                                                    <input
                                                        type="checkbox"
                                                        name="twitter"
                                                        className="form-check-input space-right"
                                                        checked={newCustomer.twitter}
                                                        onChange={handleInputChange}
                                                        disabled={!editMode}
                                                    />
                                                    <label className="label-middle">Twitter:</label>
                                                </div>
                                                <div className="col-md-6">
                                                    <input
                                                        type="text"
                                                        name="twitterLink"
                                                        className="form-control"
                                                        value={newCustomer.twitterLink}
                                                        onChange={handleInputChange}
                                                        placeholder="Twitter Link"
                                                        disabled={!editMode || !newCustomer.twitter}
                                                    />
                                                    {errorMessages.twitterLink && <div className="error-message-text">{errorMessages.twitterLink}</div>}
                                                </div>
                                            </div>

                                            <div className="row mb-3">
                                                <div className="col-md-3">
                                                    <input
                                                        type="checkbox"
                                                        name="linkedin"
                                                        className="form-check-input space-right"
                                                        checked={newCustomer.linkedin}
                                                        onChange={handleInputChange}
                                                        disabled={!editMode}
                                                    />
                                                    <label className="label-middle">LinkedIn:</label>
                                                </div>
                                                <div className="col-md-6">
                                                    <input
                                                        type="text"
                                                        name="linkedinLink"
                                                        className="form-control"
                                                        value={newCustomer.linkedinLink}
                                                        onChange={handleInputChange}
                                                        placeholder="linkedin Link"
                                                        disabled={!editMode || !newCustomer.linkedin}
                                                    />
                                                    {errorMessages.linkedinLink && <div className="error-message-text">{errorMessages.linkedinLink}</div>}
                                                </div>
                                            </div>

                                            <div className="row mb-3">
                                                <div className="col-md-3">
                                                    <input
                                                        type="checkbox"
                                                        name="instagram"
                                                        className="form-check-input space-right"
                                                        checked={newCustomer.instagram}
                                                        onChange={handleInputChange}
                                                        disabled={!editMode}
                                                    />
                                                    <label className="label-middle">Instagram:</label>
                                                </div>
                                                <div className="col-md-6">
                                                    <input
                                                        type="text"
                                                        name="instagramLink"
                                                        className="form-control"
                                                        value={newCustomer.instagramLink}
                                                        onChange={handleInputChange}
                                                        placeholder="Instagram Link"
                                                        disabled={!editMode || !newCustomer.instagram}
                                                    />
                                                    {errorMessages.instagramLink && <div className="error-message-text">{errorMessages.instagramLink}</div>}
                                                </div>
                                            </div>

                                            <div className="row mb-3">
                                                <div className="col-md-3">
                                                    <input
                                                        type="checkbox"
                                                        name="whatsapp"
                                                        className="form-check-input space-right"
                                                        checked={newCustomer.whatsapp}
                                                        onChange={handleInputChange}
                                                        disabled={!editMode}
                                                    />
                                                    <label className="label-middle">WhatsApp:</label>
                                                </div>
                                                <div className="col-md-2">
                                                    <input
                                                        type="text"
                                                        name="whatsappLink"
                                                        className="form-control"
                                                        value={newCustomer.whatsappLink}
                                                        onChange={handleInputChange}
                                                        placeholder="WhatsApp Link"
                                                        disabled={!editMode || !newCustomer.whatsapp}
                                                    />
                                                    {errorMessages.whatsappLink && <div className="error-message-text">{errorMessages.whatsappLink}</div>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="tabs">
                                {activeTab === 'themes' && (
                                    <div className="card mb-4">
                                        <h3 className="customer-subtitle">Themes</h3>
                                        <div className="card-body">
                                            <div className="row mb-3">
                                                <div className="col-md-3">
                                                    <label>Logo:</label>
                                                </div>
                                                <div className="col-md-6">

                                                    {!isEditImage && (
                                                        <div>
                                                            <img
                                                                src={cacheBustedUrl}
                                                                alt=""
                                                                className="preview-image-modal"
                                                            />
                                                            <button
                                                                onClick={handleEditImage}
                                                                className="btn btn-primary"
                                                                disabled={!editMode}
                                                            >
                                                                Change Image
                                                            </button>
                                                        </div>
                                                    )}

                                                    {isEditImage && (
                                                        <ImageUploaderWithCrop onImageChange={handleImageChange} />
                                                    )}
                                                </div>
                                            </div>

                                            <div className="row mb-3">
                                                <div className="col-md-3">
                                                    <label>Button background color:</label>
                                                </div>
                                                <div className="col-md-3">
                                                    <ColorPicker
                                                        value={newCustomer.primaryColor}
                                                        onColorChange={handlePrimaryColorChange}
                                                        disabled={!editMode}
                                                    />
                                                </div>
                                            </div>

                                            <div className="row mb-3">
                                                <div className="col-md-3">
                                                    <label>Button font color:</label>
                                                </div>
                                                <div className="col-md-3">
                                                    <ColorPicker
                                                        value={newCustomer.fontPrimaryColor}
                                                        onColorChange={handleFontPrimaryColorChange}
                                                        disabled={!editMode}
                                                    />
                                                </div>
                                            </div>

                                            <div className="row mb-3">
                                                <div className="col-md-3">
                                                    <label>Header and footer background color:</label>
                                                </div>
                                                <div className="col-md-3">
                                                    <ColorPicker
                                                        value={newCustomer.headerFooterColor}
                                                        onColorChange={handleHeaderFooterColorChange}
                                                        disabled={!editMode}
                                                    />
                                                </div>
                                            </div>

                                            <div className="row mb-3">
                                                <div className="col-md-3">
                                                    <label>Header and footer font color:</label>
                                                </div>
                                                <div className="col-md-3">
                                                    <ColorPicker
                                                        value={newCustomer.fontHeaderFooterColor}
                                                        onColorChange={handleFontHeaderFooterColorChange}
                                                        disabled={!editMode}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>

                        </div>
                    </form>
                    <ViewWebPageModal
                        isOpen={isModalOpen}
                        onClose={() => setModalOpen(false)}
                        url={currentUrl}
                    />
                </div>
            </section>
        </>
    );
};

export default Customer;
