const apiUrl = process.env.REACT_APP_LOAD_PAGE_URL;

class CategoryService {

    static async getAll(page, size, filters) {
        const token = this.getToken();

        const queryParams = new URLSearchParams({
            page,
            size,
            description: filters.description || '',
            enabled: filters.enabled || '',
        }).toString();

        const response = await fetch(`${apiUrl}/pageMenuCategory?${queryParams}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });

        this.handleUnauthorized(response);

        const data = await response.json();
        return data;
    }

    static async createCategory(newCategory) {
        const token = this.getToken();

        const response = await fetch(`${apiUrl}/pageMenuCategory`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(newCategory),
        });

        this.handleUnauthorized(response);
    }

    static async updateCategory(categoryId, updatedCategory) {
        const token = this.getToken();

        const response = await fetch(`${apiUrl}/pageMenuCategory/${categoryId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(updatedCategory),
        });

        this.handleUnauthorized(response);
    }

    static async deleteCategory(categoryId) {
        const token = this.getToken();

        const response = await fetch(`${apiUrl}/pageMenuCategory/${categoryId}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });

        this.handleUnauthorized(response);
    }

    static async updateEnabled(categoryId, updatedCategory) {
        const token = this.getToken();

        const response = await fetch(`${apiUrl}/pageMenuCategory/enabled/${categoryId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(updatedCategory),
        });

        this.handleUnauthorized(response);
    }

    static getToken() {
        return localStorage.getItem('authToken');
    }

    static handleUnauthorized(response) {
        if (response.status === 401 || response.status === 403) {

            localStorage.removeItem("authToken");
            window.location.href = "/";
        }

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
    }

}

export default CategoryService;
