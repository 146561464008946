const apiUrl = process.env.REACT_APP_LOAD_PAGE_URL;

class ProductService {

    static async getAll(page, size, filters) {

        const token = this.getToken();

        const queryParams = new URLSearchParams({
            page,
            size,
            name: filters.name || '',
            description: filters.description || '',
            enabled: filters.enabled || '',
        });

        if (filters.categoryId && !isNaN(filters.categoryId)) {
            queryParams.append('categoryId', filters.categoryId);
        }

        const queryString = queryParams.toString();

        const response = await fetch(`${apiUrl}/pageMenuProduct?${queryString}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });

        this.handleUnauthorized(response);

        const data = await response.json();
        return data;
    }

    static async createProduct(newProduct) {
        const token = this.getToken();

        const response = await fetch(`${apiUrl}/pageMenuProduct`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`
            },
            body: newProduct,
        });

        this.handleUnauthorized(response);

        return await response.json();
    }

    static async updateProduct(productId, updatedProduct) {
        const token = this.getToken();

        const response = await fetch(`${apiUrl}/pageMenuProduct/${productId}`, {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${token}`
            },
            body: updatedProduct,
        });

        this.handleUnauthorized(response);
    }

    static async deleteProduct(ProductId) {
        const token = this.getToken();

        const response = await fetch(`${apiUrl}/pageMenuProduct/${ProductId}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });

        this.handleUnauthorized(response);
    }

    static async updateEnabled(productId, updatedProduct) {
        const token = this.getToken();

        const response = await fetch(`${apiUrl}/pageMenuProduct/enabled/${productId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(updatedProduct),
        });

        this.handleUnauthorized(response);
    }

    static getToken() {
        return localStorage.getItem('authToken');
    }

    static handleUnauthorized(response) {
        if (response.status === 401 || response.status === 403) {

            localStorage.removeItem("authToken");
            window.location.href = "/";
        }

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
    }
}

export default ProductService;
