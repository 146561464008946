const apiUrl = process.env.REACT_APP_LOAD_PAGE_URL;

class LoginService {

  static async login(username, password) {
    try {
      const response = await fetch(`${apiUrl}/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      });

      this.handleUnauthorized(response);

      const data = await response.json();
      return data;
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
      throw error;
    }
  }

  static async changePassword({ password }) {
    const apiUrl = process.env.REACT_APP_LOAD_PAGE_URL;
    try {
      const response = await fetch(`${apiUrl}/login/new-password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
        },
        body: JSON.stringify({ password }),
      });

      this.handleUnauthorized(response);

      const data = await response.json();
      return data;
    } catch (error) {
      throw error;
    }
  }

  static handleUnauthorized(response) {
    if (response.status === 401 || response.status === 403) {

      localStorage.removeItem("authToken");
      window.location.href = "/";
    }

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
  }
}

export default LoginService;
