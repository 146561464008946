const apiUrl = process.env.REACT_APP_LOAD_PAGE_URL;

class CustomerService {

    static async getClientFromAuthenticatedUser() {
        const token = this.getToken();

        const response = await fetch(`${apiUrl}/customers/id`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });

        this.handleUnauthorized(response);

        const data = await response.json();
        return data;
    }

    static async updateCustomer(customerId, updatedCustomer) {
        const token = this.getToken();

        const response = await fetch(`${apiUrl}/customers/${customerId}`, {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${token}`
            },
            body: updatedCustomer,
        });

       this.handleUnauthorized(response);
    }

    static getToken() {
        return localStorage.getItem('authToken');
    }

    static handleUnauthorized(response) {
        if (response.status === 401 || response.status === 403) {

            localStorage.removeItem("authToken");
            window.location.href = "/";
        }

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
    }

}

export default CustomerService;
